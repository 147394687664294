<template>
  <div>
    <Breadcrumbs main="" title="My Profile" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="p-5">
            <b-row>
              <b-col>
                <px-card title="Profile" class="b-r-0">
                  <div slot="headerAction"></div>
                  <div slot="with-padding">
                  <b-row class="col-md-12">
                    <label class="font-weight-bold"> Email </label>
                    </b-row>
                    <b-row class="col-md-12">
                        <b-form-input
                          size="sm"
                          type="text"
                          name="email"
                          v-model="email"
                          maxlength="50"
                          placeholder="email"
                          disabled
                          required
                        ></b-form-input>
                  </b-row>
                  <b-row class="col-md-12 mt-3">
                    <label class="font-weight-bold"> Name </label>
                    </b-row>

                    <b-row class="col-md-12">
                        <b-form-input
                          size="sm"
                          type="text"
                          name="username"
                          v-model="username"
                          maxlength="50"
                          placeholder="username"
                          required
                        ></b-form-input>
                    </b-row>
                    <b-row class="col-md-12 mt-3">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="onSubmit('username')"
                        >Edit</b-button
                      >
                    </b-row>
                </div>
                </px-card>
              </b-col>
              <b-col>
                <px-card title="Update Password" class="b-r-0">
                  <div slot="headerAction"></div>
                  <div slot="with-padding">
                    <b-row class="col-md-12">
                      <label class="font-weight-bold">
                        Current Password *
                      </label>
                    </b-row>
                      <b-row class="col-md-12">
                          <b-form-input
                            size="sm"
                            type="text"
                            name="password"
                            maxlength="30"
                            v-model="oldPassword"
                            placeholder="current password"
                          ></b-form-input>
                      </b-row>
                    <b-row class="col-md-12 mt-3">
                      <label class="font-weight-bold">
                        New Password *
                      </label>
                      </b-row>
                        <b-row class="col-md-12">
                          <b-form-input
                            size="sm"
                            type="text"
                            name="password"
                            maxlength="30"
                            v-model="newPassword"
                            placeholder="new password"
                          ></b-form-input>
                    </b-row>
                    <hr />
                    <b-row class="col-md-12">
                      <label class="font-weight-bold">
                        Repeat New Password *
                      </label>
                    </b-row>
                      <b-row class="col-md-12">
                          <b-form-input
                            size="sm"
                            type="text"
                            name="password"
                            maxlength="30"
                            v-model="repeatnewPassword"
                            placeholder="new password"
                          ></b-form-input>
                      </b-row>
                    <b-row class="mt-3 col-md-12">
                        <b-button
                          variant="primary"
                          @click="onSubmit('password')"
                          >Update Password</b-button
                        >
                    </b-row>
                  </div>
                </px-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import UserController from "../../services/userController";
import UserAuth from "../auth/js/index";
export default {
  name: "Profile",
  data() {
    return {
      username: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      repeatnewPassword: "",
      updatePassword: false,
      userinfo: {},
      fullname_err_text: "",
    };
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.email = this.userinfo.email;
    this.username = this.userinfo.username;
  },
  methods: {
    async onSubmit(txt) {
      var p = {};
      switch (txt) {
        case "username":
          if (this.validateFullName()) {
            p = { username: this.username };
          } else {
            this.$toasted.show(this.fullname_err_text, {
              theme: "outline",
              position: "bottom-center",
              type: "error",
              duration: 2000,
            });
            return;
          }
          break;
        case "email":
          if (this.validateEmail(this.email)) {
            p = { email: this.email };
          } else {
            this.$toasted.show("Invalid Email", {
              theme: "outline",
              position: "bottom-center",
              type: "error",
              duration: 2000,
            });
            return;
          }
          break;
        case "password":
          if (this.validatePassword()) {
            p = {
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
            };
          }
          break;
      }
      if (Object.keys(p).length > 0) {
        let response = await UserController.profile(p);
        if (response.result) {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
          UserAuth.Logout();
          this.$router.replace("/auth/login");
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
      }
    },
    validatePassword() {
      if (this.oldPassword == "") {
        this.$toasted.show("Current Password is required", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      } else if (this.newPassword == "") {
        this.$toasted.show("New Password is required", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      } else if (this.repeatnewPassword == "") {
        this.$toasted.show("Repeat New Password is required", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      } else if (this.oldPassword == this.newPassword) {
        this.$toasted.show(
          "Current Password and New Password should not be same",
          {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          }
        );
        return false;
      } else if (this.newPassword != this.repeatnewPassword) {
        this.$toasted.show(
          "New Password and Repeat New Password should be same",
          {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          }
        );
        return false;
      } else if (this.newPassword.length < 6) {
        this.$toasted.show("Password should be minimum 6 characters", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      } else {
        return true;
      }
    },
    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },
    validateFullName() {
      var fullName = this.username;
      const regex = /^[a-zA-Z0-9 ]+$/; // Alphanumeric and space characters only
      if (!regex.test(fullName) && fullName != "") {
        this.username = "";
        this.fullname_err_text =
          "Full name can only contain alphanumeric characters and spaces";
        this.username = this.userinfo.username;
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
hr {
  margin-top: 0rem !important;
}
</style>